@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.App {
  font-family: "Outfit" !important;
  text-align: center;
  margin-top: 185px !important;
}

root {
  --background: #ffffff;
  --color-pink: #fc58a2;
  --color-purple: #5f1ec0;
  --color-deep--purple: #211237;
  --color-pink-hover: rgba(252, 88, 162, 0.2);
  --color-purple-hover: rgba(95, 30, 192, 0.2);
  --font-body: "Outfit";
  --font-family: "Outfit";
  --font-size-title: 3rem;
  --font-size-body: 2rem;
}

p {
  text-align: center;
  font-family: "Outfit" !important;
  font-size: 30px !important;
  margin-top: 0px !important;
  line-height: 1 !important;
}

h1 {
  text-align: center;
  font-family: "Outfit" !important;
  font-size: 97.807px !important;
}

h2 {
  text-align: center;
  font-family: "Outfit" !important;
  font-size: 35px !important;
}

h3 {
  text-align: center;
  font-family: "Outfit" !important;
}

h4 {
  text-align: center;
  font-family: "Outfit" !important;
  font-size: 30px !important;
  line-height: 1 !important;
  padding-bottom: 5px !important;
  margin: 0 auto;
}

col {
  text-align: center;
  font-family: var(--font-body) !important;
  margin: 0 auto;
}

.sv-components-column {
  padding-top: 0px;
}

.custom_surveyjs_root {
  background: (var(--background)) !important;
  margin: 0 auto;
}

.sd-dropdown {
  background-color: var(--color-purple-hover);
  color: var(--color-purple-hover);
  font-family: var(--font-body) !important;
  font-size: 1.5rem !important;
}

.option {
  color: var(--color-purple-hover);
  font-family: var(--font-body) !important;
  font-size: 1.5rem !important;
}

.sd-dropdown:focus,
.sd-dropdown:hover {
  box-shadow: var(--color-purple);
}

.sd-body__navigation {
  align-items: center;
  justify-content: center;
}

.sd-body {
  align-items: center;
  justify-content: center;
}

.sd-rating__item:nth-child(3) {
  width: 100px;
  height: 100px;
  border-color: #5f1ec0;
  border-width: 3.995px;
}

.sd-rating__item:nth-last-child(2) {
  width: 100px;
  height: 100px;
  border-color: #5f1ec0;
  border-width: 3.995px;
}

.sd-rating__item:nth-child(4) {
  width: 75px;
  height: 75px;
  border-color: rgb(from #5f1ec0 r g b / 80%);
  border-width: 3.995px;
}

.sd-rating__item:nth-last-child(3) {
  width: 75px;
  height: 75px;
  border-width: 3.995px;
  border-color: rgb(from #5f1ec0 r g b / 80%);
}

.sd-rating__item:nth-child(5) {
  width: 55px;
  height: 55px;
  border-color: rgb(from #5f1ec0 r g b / 60%);
  border-width: 3.995px;
}

.sd-rating__item:nth-last-child(4) {
  width: 55px !important;
  height: 55px !important;

  border-color: rgb(from #5f1ec0 r g b / 60%) !important;
  border-width: 3.995px !important;
}

.sd-rating__item:nth-child(6) {
  width: 40px;
  /* height: 40px; */
  border-color: rgb(from #5f1ec0 r g b / 40%);
  border-width: 3.995px;
}

.sd-rating__item:nth-child(7) {
  width: 40px;
  /* height: 40px; */
  border-color: rgb(from #5f1ec0 r g b / 30%);
  border-width: 3.995px;
}

.sd-rating__item:nth-last-child(5) {
  width: 40px;
  /* height: 40px; */
  border-color: rgb(from #5f1ec0 r g b / 40%);
  border-width: 3.995px;
}

.sd-rating.sd-rating--wrappable fieldset {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.sd-rating fieldset {
  display: flex;
  border: none;
  padding: 0 0calc (2 * var(--base-unit, 8px)) 0;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.sd-rating__item {
  align-items: center;
  text-align: center;
  display: flex;
  margin: 0 auto;
}

.sd-rating__item-text.sd-rating__item-text.sd-rating__min-text,
.sd-rating__item-text.sd-rating__item-text.sd-rating__max-text {
  color: #5f1ec0 !important;
  font-weight: 600 !important;
}

.sd-rating.sd-rating--wrappable fieldset {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.sd-rating fieldset {
  display: flex;
  border: none;
  padding: 0 0calc (2 * var(--base-unit, 8px)) 0;
  gap: calc(1 * var(--base-unit, 8px));
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sd-rating__item {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.sd-rating__title {
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 1.5rem;
}

.sd-rating__item--allowhover {
  cursor: pointer;
  background-color: var(--background);
}

.sd-logo {
  margin: 0 auto;
}

.sd-table-wrapper {
  background: white;
  margin: 0 auto;
  width: 100%;
  font-family: Outfit, sans-serif;
}

.sd-radio--allowhover {
  cursor: pointer;
  background-color: var(--background);
}

.sd-table__row {
  margin: 0 auto;
  width: 100%;
  font-family: Outfit, sans-serif;
  background-color: #ffffff;
}

.sd-matrix__table-wrapper {
  margin: 0 auto;
  background-color: white;
}

.sd-matrix__root {
  margin: 0 auto;
  width: 100%;
  background-color: white;
  justify-content: center;
}

.sd-table__cell--row-text {
  font-size: 3.5rem;
  background-color: white;
}

.sd-matrix__header-cell {
  background-color: white;
  font-size: 1.25rem;
}

.sd-matrix__cell {
  background-color: white;
}

.sd-matrix__row {
  background-color: white;
}

.sd-progress {
  margin: 0 auto;
  width: 530px;
  height: 9px;
  border-radius: 45.298px;
  background-color: #f7f7f9;
  font-family: "Outfit", sans-serif;
}

.sd-progress__bar {
  background-color: #fc58a2;
  height: 9px;
  border-radius: 45.298px;
}

.sd-paneldynamic__progress-text {
  font-family: "Outfit", sans-serif;
}

.sd-progress__text {
  font-family: "Outfit", sans-serif;
  color: #211237;
}

.sd-body {
  background-color: #ffffff;
}

.sd-radiogroup__title {
  font-family: "Outfit", sans-serif;
  text-align: center;
  font-size: 1.5rem;
}

.sd-radio {
  width: max-content;
  overflow: visible;
  max-width: 100%;
  display: flex;
  margin: 0 auto;
  font-family: "Outfit", sans-serif;
  white-space: nowrap;
}

.sd-radiogroup__item {
  width: 200px;
  height: 60px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-family: "Outfit", sans-serif;
  white-space: nowrap;
}

.sd-selectbase__label {
  font-family: "Outfit", sans-serif;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.sd-selectbase__item {
  font-family: "Outfit", sans-serif;
  white-space: nowrap;
}

.sd-item__control-label {
  font-family: "Outfit", sans-serif;
  font-size: 20px;
}

.sd-radio__decorator {
  background-color: white;
  border-style: solid;
  border-color: #5f1ec0;
  border-width: 3.995px;
  width: 68.95px;
  height: 68.95px;
  cursor: pointer;
}

.sd-action-bar {
  position: relative;
  top: 167px;
  margin: 0 auto;
}

.noUi-target {
  border-radius: 45.298px;
  margin: 0 auto !important;
}

.noUi-connect {
  background: #5f1ec0;
  border-radius: 45.298px;
}

.noUi-value {
  font-family: "Outfit" !important;
}

h6 {
  text-align: center;
  font-family: "Outfit" !important;
  font-size: 30px !important;
  line-height: 1 !important;
  padding-bottom: 5px !important;
  margin: 0 auto;
}

.sd-scrollable-container:not(.sd-scrollable-container--compact) {
  width: max-content;
  overflow: visible;
  max-width: 100%;
  display: flex;
  margin: 0 auto;
}

.sd-text {
  display: none;
}

.nav-button {
  margin-left: auto;
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}

.f-c-min {
  display: none;
}

.f-c-max {
  display: none;
}

.f-c-conMax {
  display: none;
}
@media only screen and (max-width: 600px) {
  .sd-question__erbox {
    position: absolute;
    font-family: "Outfit" !important;
    top: 0.3rem;
  }

  p.f-c-min.ff.fw-400.fs-20.mt-2.f-c-5F1EC0 {
    margin-bottom: 0rem;
    position: absolute;
    left: -2rem;
    top: 0.5rem;
  }

  p.f-c-conMax.ff.fw-400.fs-20.mt-2.f-c-5F1EC0 {
    position: absolute;
    left: -2rem;
    top: 21rem;
  }

  p {
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 20.87px !important;
    margin: 0 auto;
  }

  h2 {
    display: none;
  }

  h1 {
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 54.93px !important;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 18px !important;
    margin: 0 auto;
    line-height: 150%;
  }

  h3 {
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 18px !important;
    margin: 0 auto;
    line-height: 150%;
  }

  h4 {
    text-align: center;
    font-family: "Outfit" !important;
    margin: 0 auto;
  }

  .sd-item__control-label {
    font-family: "Outfit", sans-serif;
    font-size: 18px;
  }

  .sv-components-column {
    padding-top: 0px;
  }

  .f-c-211237 {
    color: #211237 !important;
    font-size: 20px !important;
  }

  .fs-28 {
    font-size: 20px !important;
    font-weight: 100 !important;
  }

  .sd-progress {
    margin-top: -125px;
    width: 306px;
    height: 9px;
    border-radius: 45.298px;
    background-color: #f7f7f9;
    font-family: "Outfit", sans-serif;
    margin-top: 60px !important;
  }

  .sd-progress__bar {
    background-color: #fc58a2;
    height: 9px;
    border-radius: 45.298px;
  }

  .sd-paneldynamic__progress-text {
    font-family: "Outfit", sans-serif;
  }

  .sd-progress__text {
    font-family: "Outfit", sans-serif;
    color: #211237;
  }

  .sd-btn {
    width: 175px;
    border-radius: 43.4px;
    font-family: "Outfit", sans-serif;
  }

  .sd-navigation__prev-btn {
    font-size: 20px;
    text-align: left;
  }

  .sd-action-bar {
    padding-top: 0px !important;
  }

  .sd-scrollable-container--compact:not(.sd-scrollable-container) {
    width: 10px;
    overflow: hidden;
    max-width: 10%;
    display: flex;
    margin: 0 auto;
  }

  .sd-rating__min-text {
    font-family: "Outfit", sans-serif !important;
    color: #5f1ec0 !important;
    margin-left: 130px !important;
    margin-bottom: -50px !important;
  }

  .sd-rating__max-text {
    font-family: "Outfit", sans-serif !important;
    position: relative;
    color: #5f1ec0 !important;
    left: 90px !important;
    bottom: 55px !important;
  }

  .sd-rating__item:nth-child(3) {
    width: 10px;
    height: 47px;
    border-color: #5f1ec0;
    border-width: 3.995px;
    margin-left: 70.5px;
  }

  .sd-rating__item:nth-last-child(2) {
    width: 10px;
    height: 47px;
    border-color: #5f1ec0;
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-child(4) {
    width: 10px;
    height: 47px;
    border-color: rgb(from #5f1ec0 r g b / 80%);
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-last-child(3) {
    width: 10px;
    height: 47px;
    border-width: 3.995px;
    border-color: rgb(from #5f1ec0 r g b / 80%);
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-child(5) {
    width: 10px;
    height: 47px;
    border-color: rgb(from #5f1ec0 r g b / 60%);
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-last-child(4) {
    width: 10px !important;
    height: 47px !important;
    border-color: rgb(from #5f1ec0 r g b / 60%) !important;
    border-width: 3.995px !important;
    margin-left: 71.5px !important;
  }

  .sd-rating__item:nth-child(6) {
    width: 10px;
    height: 47px;
    border-color: rgb(from #5f1ec0 r g b / 40%);
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-child(7) {
    width: 10px;
    height: 47px;
    border-color: rgb(from #5f1ec0 r g b / 30%);
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating__item:nth-last-child(5) {
    width: 10px;
    height: 47px;
    border-color: rgb(from #5f1ec0 r g b / 40%);
    border-width: 3.995px;
    margin-left: 71.5px;
  }

  .sd-rating.sd-rating--wrappable fieldset {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .sd-rating fieldset {
    display: flex;
    border: none;
    width: 10%;
    justify-content: center;
    align-items: center;
  }

  .sd-rating__item {
    align-items: center;
    text-align: center;
    display: flex;
  }

  .sd-rating.sd-rating--wrappable fieldset {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .sd-rating fieldset {
    display: flex;
    border: none;
    width: 10%;
    justify-content: center;
    align-items: center;
  }

  .sd-rating__item {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .sd-rating__title {
    text-align: center !important;
    width: 200px !important;
    font-family: "Outfit", sans-serif;
    font-size: 12px;
  }

  .sd-rating__item--allowhover {
    cursor: pointer;
    background-color: white !important;
  }

  .sd-rating__item-text {
    font-family: "Outfit", sans-serif;
    margin-bottom: 50px;
  }

  .sd-question__title--empty {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .sd-logo {
    display: none;
  }

  .sd-action-bar {
    position: relative;
    top: 55px;
    margin: 0 auto;
  }

  .sd-radio__decorator {
    background-color: white;
    border-style: solid;
    border-color: #5f1ec0;
    border-width: 3.995px;
    width: 40px;
    height: 40px;
  }

  .sv-q-column-5:nth-child(1) {
    margin-top: 10px;
    margin-right: -215px;
  }

  .sv-q-column-5:nth-child(2) {
    margin-top: 80px;
    margin-right: -215px;
    height: 20px !important;
  }

  .sv-q-column-5:nth-child(3) {
    margin-top: 160px;
    margin-right: -215px;
  }

  .sv-q-column-5:nth-child(4) {
    margin-top: 240px;
  }

  .sv-q-column-5:nth-child(5) {
    margin-top: 320px;
    position: relative;
    right: 218px !important;
  }

  .fs-28 {
    font-size: 20px !important;
  }

  .w-50 {
    width: 100% !important;
  }

  .sd-rating__min-text {
    display: none !important;
  }

  .sd-rating__max-text {
    display: none !important;
  }

  .f-c-min {
    position: absolute;
    display: block;
    bottom: -60px;
    right: 180px;
  }

  .f-c-max {
    position: absolute;
    display: block;
    bottom: -390px;
    right: 180px;
  }

  .f-c-conMax {
    position: absolute;
    display: block;
    bottom: -510px;
    right: 180px;
  }
}

/* New css */
.d-flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-fw800-fs97 {
  font-size: 30px !important;
  font-weight: 300 !important;
}

.ff {
  font-family: "Outfit" !important;
}

.f-c-566071 {
  color: #566071 !important;
}

.f-c-565656 {
  color: #565656 !important;
}

.f-c-000000 {
  color: #000000 !important;
}

.f-c-5F29BD {
  color: #5f29bd !important;
}

.f-c-5F1EC0 {
  color: #5f1ec0 !important;
}

.f-c-76818E {
  color: #76818e !important;
}

.f-c-211237 {
  color: #211237 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-97 {
  font-size: 97px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.m-auto {
  margin: auto;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.f-d-col {
  flex-direction: column !important;
}

.line-height-120 {
  line-height: 120% !important;
}

.w-50 {
  width: 87%;
}

.w-80 {
  width: 80% !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.m-0 {
  margin: 0 !important;
}

.sd-page .sd-page__title {
  font-family: "Outfit" !important;
  color: #211237 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.sd-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  margin: auto !important;
}

.sd-page .sd-page__description span {
  font-family: "Outfit" !important;
  color: #566071 !important;
  font-weight: 300 !important;
}

.sd-title.sd-element__title {
  font-family: "Outfit" !important;
  color: #211237 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;
}

.sd-item__control-label {
  font-family: "Outfit", sans-serif;
  font-size: 16px !important;
}

.sv-dropdown_select-wrapper {
  margin-top: 0.8rem !important;
  background-color: #f7f4fc !important;
  border-radius: 1rem !important;
}

.sd-dropdown {
  border-radius: 1rem !important;
}

.sd-question__erbox {
  font-family: "Outfit" !important;
}

.sd-dropdown__value {
  font-family: "Outfit" !important;
  font-size: 14px !important;
}

.sd-body__progress--bottom .sd-progress__text {
  font-family: "Outfit" !important;
  font-size: 14px !important;
  color: #211237 !important;
}

.ul li {
  font-family: "Outfit" !important;
  font-size: 20px !important;
}

.sd-btn--action:hover {
  color: #ffffff;
  background-color: #5f1ec0;
}

.sd-btn--action {
  color: #ffffff;
  background-color: #5f1ec0;
  border-radius: 30px;
}

input.sd-btn.sd-navigation__next-btn {
  background-color: #5f1ec0 !important;
  color: #ffff !important;
  border-radius: 30px;
  width: 12rem;
}

input.sd-btn.sd-navigation__prev-btn {
  border-radius: 30px;
  width: 12rem;
}