.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 22rem;
    background-color: #ffff;
    border-radius: 15px;
    max-height: 50rem;
    height: 18rem;
  }
  
  .popup-inner .close-btn {
    /* position: absolute; */
    bottom: 16px;
    right: 380px;
    padding: 0.8rem;
    width: 16.5rem;
  }
  
  .popup-inner .end-btn {
    background-color: #fff;
    font-family: "Outfit" !important;
    font-size: 1rem;
    color: #5f1ec0;
    border: #5f1ec0 2px solid;
    padding: 0.7rem;
    width: 16.5rem;
    border-radius: 22px;
    font-size: 1rem;
    margin-top: 0.6rem;
  }
  
  .saveButton .save-btn {
    position: absolute;
    top: 25px;
    right: 55px;
    padding: 0.3rem 0.6rem;
  }
  
  .btn-common {
    border: none;
    cursor: pointer;
    background-color: #5f1ec0;
    font-family: "Outfit" !important;
    border-radius: 22px;
    font-size: 1rem;
    color: #fff;
  }
  .d-flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    margin: auto;
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    .btn-common {
      border: none;
      cursor: pointer;
      background-color: #5f1ec0;
      font-family: "Outfit" !important;
      border-radius: 22px;
      font-size: 1rem;
      color: #fff;
    }
    .saveButton .save-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0.2rem 0.5rem !important;
    }
  
    .popup-inner .close-btn {
      /* position: absolute; */
      bottom: 5px;
      margin-top: 10px;
      right: 200px;
  
      padding: 0.8rem;
      width: 12.5rem;
    }
  
    .popup-inner {
      position: relative;
      padding: 32px;
      width: 100%;
      max-width: 320px;
      background-color: #ffff;
      border-radius: 15px;
    }
  
    .popup-inner .end-btn {
      /* position: absolute; */
      bottom: 5px;
      right: 10px;
      border-radius: 20px;
      background-color: #fff;
      font-family: "Outfit" !important;
      font-size: 1rem;
      color: #5f1ec0;
      border: #5f1ec0 1.5px;
    }
  }